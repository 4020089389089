/*
 * @Author: 封闭货车 2813002873@qq.com
 * @Date: 2022-06-24 09:32:56
 * @LastEditors: 封闭货车 2813002873@qq.com
 * @LastEditTime: 2022-07-04 11:12:00
 * @FilePath: \hh\PC\h6\src\api\page.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE获取
 */
import store from '../../store'
import request from '../request'
const http = {
    mobile: 'pc',
    wisdom: 'wisdom',
}
const apis = {
    decorationSquareShow(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=store&op=show`, params)
    },
    decorationSquareGoodsClass(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=store&op=get_store_goods_class`, params)
    },

    decorationSquareWxApiConfig(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=index&op=getWxApiConfig`, params)    
    },

    decorationSquareGoodsClassPromotion(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=store&op=get_store_goods_class_promotion`, params)
    },

    decorationSquareGoodsList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=store&op=get_store_goods_list`, params)
    },
    decorationSquareChildClass(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=store&op=get_store_child_class`, params)
    },
    decorationSquareList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=goods&op=goods_list`, params)
    },
    decorationSquareStoreSnshome(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=store_snshome&op=indexv2`, params)
    },
}
export default apis;