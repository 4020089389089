const oderData=[
    { name: "全部订单", state: '0',icon:"el-icon-notebook-2" },
    { name: "待付款", state: '1',icon:"el-icon-bank-card" },
    { name: "预付款", state: '6',icon:"el-icon-bank-card" },
    { name: "待收货", state: '3',icon:"el-icon-box" },
    { name: "已收货", state: '4',icon:"el-icon-files" },
    { name: "退货", state: '5',icon:"el-icon-edit-outline" },
    { name: "待评价", state: '4',icon:"el-icon-edit-outline" },
  ];//订单信息
export default oderData 
