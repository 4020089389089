
function format(time,value) {
    // value为true时 表示精确到时分秒 
    let datetime = new Date(Number(time) * 1000);
    let year = datetime.getFullYear();//获取完整的年份(4位,如:1970)
    let month = datetime.getMonth() + 1;//获取月份(返回0-11,0代表1月,用的时候记得加上1)
    let date = datetime.getDate();//获取日(返回1-31)
    var hours= datetime.getHours();//获取时
    var minutes = datetime.getMinutes();//获取分
    var seconds = datetime.getSeconds();//获取秒
    //判断小于等于9月的时候在月份前加一个"0"
    if (month <= 9) month = "0" + month; 
    //判断小于等于9号的时候在天数前加一个"0"
    if (date <= 9)  date = "0" + date;
    //判断小于等于9小时得时候,在小时前加一个"0"
    if (hours <= 9) hours = "0" + hours;
    //判断小于等于9分钟得时候,在小时前加一个"0"
    if (minutes <= 9) minutes = "0" + minutes;
    //判断小于等于9秒钟得时候,在小时前加一个"0"
    if (seconds <= 9) seconds = "0" + seconds;
    let dateToday;
    if(!value){
        dateToday = `${year}-${month}-${date}`;
    }else{
        dateToday = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
    }
    return dateToday;
}

export default format;