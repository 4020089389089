/*
 * @Author: 封闭货车 2813002873@qq.com
 * @Date: 2022-06-24 09:32:56
 * @LastEditors: maojiawei 18970691631@163.com
 * @LastEditTime: 2023-11-13 16:45:49
 * @FilePath: \hh\PC\h6\src\api\page.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE获取
 */
import store from '../../store'
import request from '../request'
const http = {
    mobile: 'pc',
    live: 'live',
    wisdom: 'wisdom'
}
const apis = {
    //获取首页列表栏数据
    navMenuLists(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=index&op=recommend_class`)
    },
    //获取品牌馆数据
    // recommandList(params) {
    //     return request('get', `${store.state.api}/${http.mobile}/index.php?act=goods&op=class_goods_list`, params)
    // },
    //获取商品列表数据
    commodityList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=goods&op=goods_list`, params)
    },
    // 获取店铺数据
    storeList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=store&op=list_app`, params)
    },
    //获取首页数据
    homeList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=index&op=index`, params)
    },
    //获取首页商品详情
    productDetailsList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=index&op=recommend`, params)
    },
    //获取首页促销列表
    promotion(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=index&op=recommend_class`, params)
    },
    //获取直播列表数据
    liveBroadcastList(params) {
        return request('get', `${store.state.api}/${http.live}/index.php?act=api-live&op=getLiveList`, params)
    },
    //获取直播信息
    getLiveRoomInfo(params) {
        return request('get', `${store.state.api}/${http.live}/index.php?act=api-live&op=getLiveRoomInfo`, params)
    },
    //直播关注
    modifyLiveBroadcastFollow(params) {
        return request('get', `${store.state.api}/${http.live}/index.php?act=api-live&op=followAnchor`, params)
    },
    //取消直播关注
    cancelLiveBroadcastFollow(params) {
        return request('get', `${store.state.api}/${http.live}/index.php?act=api-live&op=cancelFollowAnchor`, params)
    },
    //获取新闻中心信息
    // getNewsCenterInfo(params) {
    //     return request('get', `${store.state.api}/${http.mobile}/index.php?act=cms&op=classdisplay`, params)
    // },
    //获取商品详情
    GoodsDetailsList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=goods&op=goods_detail`, params)
    },
    //获取智慧商场
    SmartShoppingMallList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=api-wisdom&op=vrmall_list`, params)
    },
    //获取广告
    AllClassList(params) {
        return request('get', `${store.state.api}/${http.live}/index.php?act=api-live&op=getAllClass`, params)
    },
    AdvClassList(params) {
        return request('get', `${store.state.api}/${http.live}/index.php?act=api-live&op=liveIndexAdv`, params)
    },

    //获取首页设计方案列表
    DesignList(params) {
        return request('get', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=aesthetics_case_list`, params)
    },

    //获取首页短视频列表
    VideoList(params) {
        return request('get', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=aesthetics_case_list`, params)
    },

    //获取首页新闻资讯列表
    ConsultingList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=cms&op=list`, params)
    },
    // 获取热门搜索数据
    HotSearchList() {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=search&op=hot_history`)
    },
    //  // 获取店铺数据
    //  SearchList(params) {
    //     return request('get', `${store.state.api}/${http.mobile}/index.php?act=goods&op=goods_list`, params)
    // },
    // 获取商品详情店铺
    ShopList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_cart&op=cart_list`, params)
    },

    // 搜索匹配项数据
    matches(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=search&op=aotocomplete`, params)
    },
    // 获取装修选择框列表
    DecorateList(params) {
        return request('POST', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=aesthetics_category_list`, params)
    },
    // 装修案例列表
    Decorates(params) {
        return request('get', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=aesthetics_case_list`, params)
    },
    // 短视频详情
    DecorationDetail(params) {
        return request('post', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=aesthetics_case_info`, params)
    },

    // 新品资讯详情
    realTimeInfo(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=cms&op=view`, params)
    },

    // 智慧商场详情
    smartMall(params) {
        return request('get', `${store.state.api}/${http.wisdom}/index.php?act=api-wishop&op=vrmall_list`, params)
    },

    //获取列表栏数据
    aboutUs() {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=document&op=about`);
    },

    //获取购物车列表栏数据
    shoppings(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_cart&op=cart_list`, params)
    },

    //增减购物车商品
    plusReduceShopping(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_cart&op=cart_edit_quantity`, params)
    },

    //删除购物车商品
    deleteShopping(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_cart&op=cart_del`, params)
    },

    //获取购物车商品总价
    shoppingSum(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_cart&op=cart_select_change`, params)
    },
    //获取列表栏数据
    navMenuLists() {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=index&op=recommend_class`)
    },
    //获取促销列表
    promotion(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=goods_class&op=getChildByRecommend`, params)
    },
    //获取分类列表
    promotionClassification(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=goods_class&op=getChildByFirstId`, params)
    },

    //获取店铺列表
    shopping(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=store&op=list_app`,params)
    },

    //获取品牌列表
    brand(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=brand&op=list`,params)
    },


    //获取全部订单列表
    orderList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_order&op=order_list`, params)
    },

    //获取物流信息
    searchDeliver(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_order&op=search_deliver`, params)
    },

    //申请退款
    saveRefundAll(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_refund&op=save_refund_all`, params)
    },

    //确认收货
    orderReceive(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_order&op=order_receive`, params)
    },

    //取消订单
    orderCancel(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_order&op=order_cancel`, params)
    },
    //取消订单
    orderCancel(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_order&op=order_cancel`, params)
    },
    //获取会员消息
    getMember_Message(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_message&op=showReceivedNewNum`, params)
    },
    //获取系统消息列表
    getSystemmsgMessageList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_message&op=systemmsg`, params)
    },
    // 获取推送消息 / 站内消息信息
    getSystemmsgStationmsgInfo(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_message&op=stationmsg`, params)
    },
    //修改未读消息
    modifySystemmsgMessage_open(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_message&op=message_open`, params)
    },
    //我的小店分销信息
    getDistributionInfo(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=distribution&op=index`, params)
    },
    //我的小店二维码等列表
    getDistributionGoodsList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=distribution&op=goodsList`, params)
    },
    //钻点明细列表
    getStampsPointList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=distribution&op=commission_log_list`, params)
    },
    //富豪榜列表
    getPlutocratRankingList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=distribution&op=rich_list`, params)
    },
    //分销订单数据列表
    getDistributionList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=distribution&op=getOrderList`, params)
    },
    //获取我的团队列表
    getMyTeamList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=distribution&op=under_com`, params)
    },
    //获取小店设置信息
    getStoreSettingsInfo(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=distribution&op=getShopInfo`, params)
    },
    //小店设置上传接口
    storeSettingsUpload(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=distribution&op=shopAuthUpload`, params)
    },
    // 小店设置保存设置信息
    saveSettingsInfo(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=distribution&op=submitShopInfo`, params)
    },
}
export default apis;