/*
 * @Author: 封闭货车 2813002873@qq.com
 * @Date: 2022-06-24 09:32:56
 * @LastEditors: 封闭货车 2813002873@qq.com
 * @LastEditTime: 2022-07-04 11:12:00
 * @FilePath: \hh\PC\h6\src\api\page.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE获取
 */
import store from '../../store'
import request from '../request'
const http = {
    mobile: 'pc',
    // wisdom: 'wisdom',
    butler: 'butler'
}
const apis = {
    //新闻详情
    journaList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=cms&op=list`, params)
    },
    //更多详情
    moreList(params) {
        return request('get', `${store.state.api}/${http.butler}/index.php?act=api-article&op=index`, params)
    },

    moreDetailsList(params) {
        return request('get', `${store.state.api}/${http.butler}/index.php?act=api-article&op=list`, params)
    },
    //论坛详情
    forumList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=forum&op=index`, params)
    },
    
    // 发布的论坛详情
    forumViewDetail(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=forum&op=view`, params)
    },

    // 发布的论坛详情评论
    forumViewCommentAdd(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=forum&op=comment_add`, params)
    },
    // 社区中心主页详情
    community(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=cms&op=index`, params)
    },

    //新闻详情
    journalism(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=cms&op=classdisplay`, params)
    },
}
export default apis;