import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import {isLoadingRequest,isLoadingResponse} from '../api/request'

/***** Entrepreneur *****/
const EntrepreneurDetails =  r => require.ensure([], () => r(require('../layout/entrepreneur/entrepreneur.vue')), 'EntrepreneurDetails')
const EntrepreneurMakerForum =  r => require.ensure([], () => r(require('../layout/entrepreneur/makerForumStyle/makerForum.vue')), 'EntrepreneurMakerForum')
const EntrepreneurMakerStyle =  r => require.ensure([], () => r(require('../layout/entrepreneur/makerForumStyle/makerStyle.vue')), 'EntrepreneurMakerStyle')
const EntrepreneurMoreDetails =  r => require.ensure([], () => r(require('../layout/entrepreneur/makerForumStyle/moreAndVideo/moreDetails.vue')), 'EntrepreneurMoreDetails')
const EntrepreneurVideoDetails =  r => require.ensure([], () => r(require('../layout/entrepreneur/makerForumStyle/moreAndVideo/videoDetails.vue')), 'EntrepreneurVideoDetails')

/***** CommunityCenter *****/
const CommunityCommunity =  r => require.ensure([], () => r(require('../layout/communityCenter/community.vue')), 'CommunityCommunity')
const CommunityForum =  r => require.ensure([], () => r(require('../layout/communityCenter/refinedSteelDetails/forum/forum.vue')), 'CommunityForum')
const CommunityForumView =  r => require.ensure([], () => r(require('../layout/communityCenter/refinedSteelDetails/forum/forumView.vue')), 'CommunityForumView')

/***** CityOfDesign *****/
const cityOfDesignDetails =  r => require.ensure([], () => r(require('../layout/cityOfDesign/designDetails.vue')), 'cityOfDesignDetails')
const cityOfDesignEstheticsRenovation =  r => require.ensure([], () => r(require('../layout/cityOfDesign/refinedSteelDetails/estheticsRenovation.vue')), 'cityOfDesignEstheticsRenovation')
const cityOfDesignProgramme =  r => require.ensure([], () => r(require('../layout/cityOfDesign/refinedSteelDetails/programme.vue')), 'cityOfDesignProgramme')
const cityOfDesignDesignRanking =  r => require.ensure([], () => r(require('../layout/cityOfDesign/refinedSteelDetails/designRanking.vue')), 'cityOfDesignDesignRanking')
const cityOfDesignLookForDesign =  r => require.ensure([], () => r(require('../layout/cityOfDesign/refinedSteelDetails/lookForDesign.vue')), 'cityOfDesignLookForDesign')
// dz
const gongchengDzdetail =  r => require.ensure([], () => r(require('../layout/cityOfDesign/refinedSteelDetails/gongcheng/dzdetail.vue')), 'gongchengDzdetail')
const gongchengEnterprise =  r => require.ensure([], () => r(require('../layout/cityOfDesign/refinedSteelDetails/gongcheng/enterprise.vue')), 'gongchengEnterprise')
const gongchengFormserver =  r => require.ensure([], () => r(require('../layout/cityOfDesign/refinedSteelDetails/gongcheng/formserver.vue')), 'gongchengFormserver')
const gongchengLeader =  r => require.ensure([], () => r(require('../layout/cityOfDesign/refinedSteelDetails/gongcheng/leader.vue')), 'gongchengLeader')
const gongchengLeddetail=  r => require.ensure([], () => r(require('../layout/cityOfDesign/refinedSteelDetails/gongcheng/leddetail.vue')), 'gongchengLeddetail')

/***** GroupExchange *****/
const GroupExchange =  r => require.ensure([], () => r(require('../layout/groupExchange/groupExchange.vue')), 'GroupExchange')

/***** SechaFestival *****/
const SechaFestival =  r => require.ensure([], () => r(require('../layout/sechaFestival/sechaFestival.vue')), 'SechaFestival')

/***** Search *****/
const Search =  r => require.ensure([], () => r(require('../layout/search/search.vue')), 'Search')

/***** publicPage *****/
const PageGoodsDetails =  r => require.ensure([], () => r(require('../layout/publicPage/goodsdetails.vue')), 'PageGoodsDetails')
const PublicVideo =  r => require.ensure([], () => r(require('../layout/publicPage/video.vue')), 'PublicVideo')
const PublicRealTimeInfo =  r => require.ensure([], () => r(require('../layout/publicPage/realTimeInfo.vue')), 'PublicRealTimeInfo')
const PublicShoppingList =  r => require.ensure([], () => r(require('../layout/publicPage/shoppingList.vue')), 'PublicShoppingList')
const PublicPageDesignScheme =  r => require.ensure([], () => r(require('../layout/publicPage/designScheme.vue')), 'PublicPageDesignScheme')
const PublicPageCaseList =  r => require.ensure([], () => r(require('../layout/publicPage/caseList.vue')), 'PublicPageCaseList')
const PublicPageMoreDetails =  r => require.ensure([], () => r(require('../layout/publicPage/moreDetails.vue')), 'PublicPageMoreDetails')
const PublicPageJournalism =  r => require.ensure([], () => r(require('../layout/publicPage/journalism.vue')), 'PublicPageJournalism')
const PublicIframe =  r => require.ensure([], () => r(require('../layout/publicPage/iframe.vue')), 'PublicIframe')
const PublicDesignerDetails =  r => require.ensure([], () => r(require('../layout/publicPage/designerDetails.vue')), 'PublicDesignerDetails')
const PublicSignInSpecialOffer =  r => require.ensure([], () => r(require('../layout/publicPage/signInSpecialOffer.vue')), 'PublicSignInSpecialOffer')
const PublicPayment =  r => require.ensure([], () => r(require('../layout/publicPage/payment.vue')), 'PublicPayment')
const PublicConfirmOrder =  r => require.ensure([], () => r(require('../layout/publicPage/confirmOrder.vue')), 'PublicConfirmOrder')
const publicBecomeMember =  r => require.ensure([], () => r(require('../layout/publicPage/becomeMember.vue')), 'publicBecomeMember')
const messageCenter =  r => require.ensure([], () => r(require('../layout/publicPage/messageCenter.vue')), 'messageCenter')
const PublicShipToAddress =  r => require.ensure([], () => r(require('../layout/publicPage/shipToAddress.vue')), 'PublicShipToAddress')
const publicConfirmPayment =  r => require.ensure([], () => r(require('../layout/publicPage/confirmPayment.vue')), 'confirmPayment')
const publicOrderRefund =  r => require.ensure([], () => r(require('../layout/publicPage/orderRefund.vue')), 'orderRefund')
const publicFeedback =  r => require.ensure([], () => r(require('../layout/publicPage/feedback.vue')), 'feedback')
const test =  r => require.ensure([], () => r(require('../layout/publicPage/test.vue')), 'test')

/***** publicMessageCenter *****/ // systemMessages   
const systemMessages =  r => require.ensure([], () => r(require('../layout/publicPage/messageCenter/systemMessages.vue')), 'systemMessages')
const activityNotice =  r => require.ensure([], () => r(require('../layout/publicPage/messageCenter/systemMessages.vue')), 'activityNotice')
const instationNotice =  r => require.ensure([], () => r(require('../layout/publicPage/messageCenter/systemMessages.vue')), 'instationNotice')
const officialPush =  r => require.ensure([], () => r(require('../layout/publicPage/messageCenter/systemMessages.vue')), 'officialPush')

/***** smartEngineering *****/
const SmartEngineering =  r => require.ensure([], () => r(require('../layout/smartEngineering/smartEngineering.vue')), 'SmartEngineering')

/***** renovationNewHome *****/
const RenovationNewHome =  r => require.ensure([], () => r(require('../layout/renovationNewHome/renovationNewHome.vue')), 'RenovationNewHome')
const BasicHardMount =  r => require.ensure([], () => r(require('../layout/renovationNewHome/basicHardMount.vue')), 'BasicHardMount')
const HardMountApplyList =  r => require.ensure([], () => r(require('../layout/renovationNewHome/hardMountApplyList.vue')), 'HardMountApplyList')
const ApplyOrderDetails =  r => require.ensure([], () => r(require('../layout/renovationNewHome/applyOrderDetails.vue')), 'ApplyOrderDetails')
/***** smartMall *****/
const SmartMall =  r => require.ensure([], () => r(require('../layout/smartMall/smartMall.vue')), 'SmartMall')
const SmartIndex =  r => require.ensure([], () => r(require('../layout/smartMall/smartIndex.vue')), 'SmartIndex')
const SmartMallMarketClassification =  r => require.ensure([], () => r(require('../layout/smartMall/marketClassification/marketClassification.vue')), 'SmartMallMarketClassification')
const SmartMallSeries =  r => require.ensure([], () => r(require('../layout/smartMall/marketClassification/caseVideo/series.vue')), 'SmartMallSeries')
const SmartMallVideoList =  r => require.ensure([], () => r(require('../layout/smartMall/marketClassification/caseVideo/videoList.vue')), 'SmartMallVideoList')

/***** ExchangeCoupons *****/
const ExchangeCoupons =  r => require.ensure([], () => r(require('../layout/exchangeCoupons/exchangeCoupons.vue')), 'ExchangeCoupons')
const memberZone =  r => require.ensure([], () => r(require('../layout/exchangeCoupons/memberZone.vue')), 'memberZone')
const ticketingDetails =  r => require.ensure([], () => r(require('../layout/exchangeCoupons/ticketingDetails.vue')), 'ticketingDetails')
const ExchangeList =  r => require.ensure([], () => r(require('../layout/exchangeCoupons/exchange/exchangeList.vue')), 'ExchangeList')
const ExchangeView =  r => require.ensure([], () => r(require('../layout/exchangeCoupons/exchange/exchangeView.vue')), 'ExchangeView')
const recharging =  r => require.ensure([], () => r(require('../layout/exchangeCoupons/recharging.vue')), 'recharging')

/***** decorationSquare *****/
const DecorationSquare =  r => require.ensure([], () => r(require('../layout/decorationSquare/decorationSquare.vue')), 'DecorationSquare')

/***** Home *****/
const Home =  r => require.ensure([], () => r(require('../layout/home/home.vue')), 'Home')
const My =  r => require.ensure([], () => r(require('../layout/home/my.vue')), 'My')
const ShoppingCard =  r => require.ensure([], () => r(require('../layout/home/shoppingcard.vue')), 'ShoppingCard')
const Category =  r => require.ensure([], () => r(require('../layout/home/category.vue')), 'Category')
const MyOrder =  r => require.ensure([], () => r(require('../layout/home/myOrder.vue')), 'MyOrder')
const personal =  r => require.ensure([], () => r(require('../layout/home/personal.vue')), 'personal')
const returnRefund =  r => require.ensure([], () => r(require('../layout/home/returnRefund.vue')), 'returnRefund')
const returnRefundDetails =  r => require.ensure([], () => r(require('../layout/home/returnRefundDetails.vue')), 'returnRefundDetails')
const couponOrder =  r => require.ensure([], () => r(require('../layout/home/couponOrder.vue')), 'couponOrder')
const myShop =  r => require.ensure([], () => r(require('../layout/home/myShop.vue')), 'myShop')

/***** myShop ******/ 
const stampsPointDetails =  r => require.ensure([], () => r(require('../layout/home/myShop/stampsPointDetails.vue')), 'stampsPointDetails')
const plutocratRankingList =  r => require.ensure([], () => r(require('../layout/home/myShop/plutocratRankingList.vue')), 'plutocratRankingList')
const myStoreDetails =  r => require.ensure([], () => r(require('../layout/home/myShop/myStoreDetails.vue')), 'myStoreDetails')
const distributionOrder =  r => require.ensure([], () => r(require('../layout/home/myShop/distributionOrder.vue')), 'distributionOrder')
const myTeam =  r => require.ensure([], () => r(require('../layout/home/myShop/myTeam.vue')), 'myTeam')
const storeSetup =  r => require.ensure([], () => r(require('../layout/home/myShop/storeSetup.vue')), 'storeSetup')
const selectProduct =r =>require.ensure([],()=>r(require('@/layout/home/myShop/selectProduct.vue')),'selectProduct')
const optionalProduct = r =>require.ensure([],()=>r(require('@/layout/home/myShop/optionalProduct.vue')),'optionalProduct')


/* MyOrder/orderManagement */ 
const logisticsDetails =  r => require.ensure([], () => r(require('../layout/home/orderManagement/logisticsDetails.vue')), 'logisticsDetails')
const orderDetails =  r => require.ensure([], () => r(require('../layout/home/orderManagement/orderDetails.vue')), 'orderDetails')

/***** personal *****/
const changePassword =  r => require.ensure([], () => r(require('../layout/home/personalSection/changePassword.vue')), 'changePassword')
const paymentPassword =  r => require.ensure([], () => r(require('../layout/home/personalSection/paymentPassword.vue')), 'paymentPassword')
const personalCenterHome =  r => require.ensure([], () => r(require('../layout/home/personalSection/personalCenterHome.vue')), 'personalCenterHome')
const MyGroup =  r => require.ensure([], () => r(require('../layout/home/personalSection/MyGroup.vue')), 'MyGroup')
const MyCollection =  r => require.ensure([], () => r(require('../layout/home/personalSection/MyCollection.vue')), 'MyCollection')
const returnGoods =  r => require.ensure([], () => r(require('../layout/home/personalSection/returnGoods.vue')), 'returnGoods')
const MyAppointment =  r => require.ensure([], () => r(require('../layout/home/personalSection/MyAppointment.vue')), 'MyAppointment')
const accountSecurity =  r => require.ensure([], () => r(require('../layout/home/personalSection/accountSecurity.vue')), 'accountSecurity')
const bindMobilePhone =  r => require.ensure([], () => r(require('../layout/home/personalSection/bindMobilePhone.vue')), 'bindMobilePhone')
const MyBalance =  r => require.ensure([], () => r(require('../layout/home/personalSection/MyBalance.vue')), 'MyBalance')
const MyKinshipNumber =  r => require.ensure([], () => r(require('../layout/home/personalSection/MyKinshipNumber.vue')), 'MyKinshipNumber')
const makerStore =  r => require.ensure([], () => r(require('../layout/home/personalSection/makerStore.vue')), 'makerStore')

/***** Login *****/
const login =  r => require.ensure([], () => r(require('../layout/login/login.vue')), 'login')

Vue.use(VueRouter)

const routes = [//添加路由对象

  /***** Home *****/
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home,
    name: 'home',
    meta: { 
      isNavBar: true,
    }
  },
  {
    path: '/my',
    name: 'my',
    component: My,
    meta: { isNavBar: true }
  },
  {
    path: '/shopping',
    name: 'shopping',
    component: ShoppingCard,
    meta: {  isNavBar: true,isLogin:true }
  },
  {
    path: '/category',
    name: 'category',
    component: Category,
    meta: { isNavBar: true }
  },
  {
    path: '/myOrder',
    name: 'myOrder',
    component: MyOrder,
    meta: { isNavBar: true,isLogin:true }
  },
  {
    path: '/myOrder/logisticsDetails',
    name: 'logisticsDetails',
    component: logisticsDetails,
  },
  {
    path: '/myOrder/orderDetails/:orderId',
    name: 'orderDetails',
    component: orderDetails,
  },
  {
    path:"/myOrder/returnRefund",
    name:"returnRefund",
    component:returnRefund,
  },
  {
    path:"/myOrder/returnRefund/returnRefundDetails",
    name:"returnRefundDetails",
    component:returnRefundDetails,
  },
  {
    path:"/personal/couponOrder",
    name:"couponOrder",
    component:couponOrder,
  },
  {
    path:"/personal/makerStore",
    name:"makerStore",
    component:makerStore,
  },
  /**** 我的小店 ****/ 
  {
    path:"/personal/myShop",
    name:"myShop",
    component:myShop,
    meta:{
      is_not_fallback:true
    }
  },
  /**** 我的小店钻点明细列表 ****/ 
  {
    path:"/personal/myShop/stampsPointDetails",
    name:"stampsPointDetails",
    component:stampsPointDetails,
  },
  /**** 我的小店富豪榜列表 ****/ 
  {
    path:"/personal/myShop/plutocratRankingList",
    name:"plutocratRankingList",
    component:plutocratRankingList,
  },
  /**** 我的小店分销订单 ****/ 
  {
    path:"/personal/myShop/distributionOrder",
    name:"distributionOrder",
    component:distributionOrder,
  },
  /**** 我的团队 ****/ 
  {
    path:"/personal/myShop/myTeam",
    name:"myTeam",
    component:myTeam,
  },
  /**** 我的小店详情 ****/ 
  {
    path:"/personal/myShop/myStoreDetails",
    name:"myStoreDetails",
    component:myStoreDetails,
  },
  /**** 我的小店设置 ****/ 
  {
    path:"/personal/myShop/storeSetup",
    name:"storeSetup",
    component:storeSetup,
  },
  // 自选商品
  {
    path:"/personal/myShop/selectProduct",
    name:"selectProduct",
    component:selectProduct,
  },
  //选择自选商品
  {
    path:"/personal/myShop/optionalProduct",
    name:"optionalProduct",
    component:optionalProduct,
  },
  {
    path: '/personal',
    name: 'personal',
    component: personal,
    meta: {  isNavBar: true, isLogin:true },
    children:[
      {
        path:"personalCenterHome",
        name:"personalCenterHome",
        components:{
          personalCenterHome:personalCenterHome,
        },
        meta: { 
          isNavBar: true
        },
      },
      {
        path:"changePassword",
        name:"changePassword",
        components:{
          changePassword:changePassword,
        },
        meta: { 
          isNavBar: true
        },
      },
      {
        path:"paymentPassword",
        name:"paymentPassword",
        components:{
          paymentPassword:paymentPassword,
        },
        meta: { 
          isNavBar: true
        },
      },
      {
        path:"MyCollection",
        name:"MyCollection",
        components:{
          MyCollection:MyCollection,
        },
        meta: { 
          isNavBar: true
        },
      },
      {
        path:"MyBalance",
        name:"MyBalance",
        components:{
          MyBalance:MyBalance,
        },
        meta: { 
          isNavBar: true
        },
      },
      {
        path:"MyKinshipNumber",
        name:"MyKinshipNumber",
        components:{
          MyKinshipNumber:MyKinshipNumber,
        },
        meta: { 
          isNavBar: true
        },
      },
      {
        path:"MyGroup",
        name:"MyGroup",
        components:{
          MyGroup:MyGroup,
        },
        meta: { 
          isNavBar: true
        },
      },
      {
        path:"MyCoupon",
        name:"MyCoupon",
        components:{
          MyCoupon:MyGroup,
        },
        meta: { 
          isNavBar: true
        },
      },
      {
        path:"returnGoods",
        name:"returnGoods",
        components:{
          returnGoods:returnGoods,
        },
        meta: { 
          isNavBar: true
        },
      },
      {
        path:"MyAppointment",
        name:"MyAppointment",
        components:{
          MyAppointment:MyAppointment,
        },
        meta: { 
          isNavBar: true
        },
      },
      {
        path:"accountSecurity",
        name:"accountSecurity",
        components:{
          accountSecurity:accountSecurity,
        },
        meta: { 
          isNavBar: true
        },
      },
      {
        path:"bindMobilePhone",
        name:"bindMobilePhone",
        components:{
          bindMobilePhone:bindMobilePhone,
        },
        meta: { 
          isNavBar: true
        },
      },
    ],
  },
  /***** publicPage *****/
  {
    path: '/messageCenter',
    name: 'messageCenter',
    component: messageCenter,
    meta:{ isLogin:true },
    children:[
    //   {
    //         path:"systemMessages",
    //         name:"systemMessages",
    //         components:{
    //           systemMessages:systemMessages,
    //         },
    //   meta: { 
    //     isNavBar: true
    //   },
    // },
    {
      path:"systemMessages",
      name:"systemMessages",
      components:{
        systemMessages:systemMessages,
      },
      meta:{
        is_not_fallback:true
      }
    },
    {
      path:"activityNotice",
      name:"activityNotice",
      components:{
        activityNotice:activityNotice,
      },
      meta:{
        is_not_fallback:true
      }
    },
    {
      path:"instationNotice",
      name:"instationNotice",
      components:{
        instationNotice:instationNotice,
      },
      meta:{
        is_not_fallback:true
      }
    },
    {
      path:"officialPush",
      name:"officialPush",
      components:{
        officialPush:officialPush,
      },
      meta:{
        is_not_fallback:true
      }
    },
    ]
  },
  {
    path: '/publicPage/goodsdetails',
    name: 'publicPageGoodsdetails',
    component: PageGoodsDetails,
    // meta: { isNavBar: true }
  },
  {
    path: '/publicPage/video',
    name: 'publicPageVideo',
    component: PublicVideo,
  },
  {
    path: '/publicPage/realTimeInfo',
    name: 'publicPageRealTimeInfo',
    component: PublicRealTimeInfo,
  },
  {
    path: '/publicPage/shoppingList',
    name: 'publicPageShoppingList',
    component: PublicShoppingList,
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/publicPage/designScheme',
    name: 'publicPageDesignScheme',
    component: PublicPageDesignScheme
  },

  {
    path: '/publicPage/caseList',
    name: 'publicPageCaseList',
    component: PublicPageCaseList,
    meta:{   keepAlive:true  },
  },
  {
    path: '/publicPage/iframe',
    name: 'publicPageIframe',
    component: PublicIframe,
    meta: { isNavBar: true }
  },
  {
    path: '/publicPage/journalism',
    name: 'publicPageJournalism',
    component: PublicPageJournalism,
    meta:{  keepAlive:true  }
  },
  {
    path: '/publicPage/moreDetails',
    name: 'publicPageMoreDetails',
    component: PublicPageMoreDetails,
    meta:{ keepAlive:true }
  },
  {
    path: '/publicPage/designerDetails',
    name: 'publicPageDesignerDetails',
    component: PublicDesignerDetails
  },
  {
    path: '/publicPage/signInSpecialOffer',
    name: 'publicPageSignInSpecialOffer',
    component: PublicSignInSpecialOffer,
    meta:{ isLogin:true }
  },
  {
    path: '/publicPage/payment',
    name: 'publicPayment',
    component: PublicPayment
  },
  {
    path: '/publicPage/confirmOrder',
    name: 'publicConfirmOrder',
    component: PublicConfirmOrder,
    meta:{ isLogin:true }
  },
  {
    path: '/publicPage/becomeMember',
    name: 'publicBecomeMember',
    component: publicBecomeMember,
    meta:{ isLogin:true,keepAlive:true }
  },
  {
    path: '/publicPage/confirmPayment',
    name: 'publicConfirmPayment',
    component: publicConfirmPayment,
    meta:{ isLogin:true }
  },
  {
    path: '/publicPage/orderRefund',
    name: 'publicOrderRefund',
    component: publicOrderRefund,
    meta:{ isLogin:true }
  },
  // publicFeedback
  {
    path: '/publicPage/feedback',
    name: 'publicFeedback',
    component: publicFeedback,
    meta:{ isLogin:true }
  },
  {
    path: '/publicPage/test',
    name: 'test',
    component: test,
    meta:{ isLogin:true }
  },
  {
    path: '/publicPage/shipToAddress',
    name: 'publicShipToAddress',
    component: PublicShipToAddress
  },

  /***** renovationNewHome *****/
  {
    path: '/renovationNewHome',
    name: 'renovationNewHome',
    component: RenovationNewHome,
    meta:{ keepAlive:true }
  },
  {
    path: '/basicHardMount',
    name: 'basicHardMount',
    component: BasicHardMount,
  },
  {
    path: '/hardMountApplyList',
    name: 'hardMountApplyList',
    component: HardMountApplyList,
  },
  {
    path: '/applyOrderDetails',
    name: 'applyOrderDetails',
    component: ApplyOrderDetails,
  },
  /***** smartMall *****/
  {
    path: '/smartMall',
    name: 'smartMall',
    component: SmartMall,
  },
  {
    path: '/smartIndex',
    name: 'smartIndex',
    component: SmartIndex,
  },
  {
    path: '/smartMall/marketClassification',
    name: 'smartMallMarketClassification',
    component: SmartMallMarketClassification,
  },

  {
    path: '/smartMall/marketClassification/series',
    name: 'smartMallMarketClassificationSeries',
    component: SmartMallSeries,
  },
  {
    path: '/smartMall/marketClassification/videoList',
    name: 'smartMallMarketClassificationVideoList',
    component: SmartMallVideoList,
  },

  /***** Login *****/
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: { 
      isNavBar: true 
    }
  },

  /***** communityCenter *****/
  {
    path: '/community',
    name: 'community',
    component: CommunityCommunity,
    meta:{ keepAlive:true }
  },
  {
    path: '/community/forum',
    name: 'communityForum',
    component: CommunityForum
  },
  {
    path: '/community/forum/forumView',
    name: 'communityForumForumView',
    component: CommunityForumView
  },
  /***** CityOfDesign *****/
  {
    path: '/designDetails',
    name: 'designDetails',
    component: cityOfDesignDetails
  },
  {
    path: '/designDetails/estheticsRenovation',
    name: 'designDetailsEstheticsRenovation',
    component: cityOfDesignEstheticsRenovation
  },
  {
    path: '/designDetails/programme',
    name: 'designDetailsProgramme',
    component: cityOfDesignProgramme
  },
  
  {
    path: '/designDetails/designRanking',
    name: 'designDetailsDesignRanking',
    component: cityOfDesignDesignRanking,
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/designDetails/lookForDesign',
    name: 'designDetailsLookForDesign',
    component: cityOfDesignLookForDesign
  },
  {
    path: '/gongcheng/dzdetail',
    name: 'dzdetail',
    component: gongchengDzdetail
  },
  {
    path: '/gongcheng/enterprise',
    name: 'enterprise',
    component: gongchengEnterprise
  },
  {
    path: '/gongcheng/formserver',
    name: 'formserver',
    component: gongchengFormserver
  },
  {
    path: '/gongcheng/leader',
    name: 'leader',
    component: gongchengLeader
  },
  {
    path: '/gongcheng/leddetail',
    name: 'leddetail',
    component: gongchengLeddetail
  },

  /***** Entrepreneur *****/
  {
    path: '/entrepreneur',
    name: 'entrepreneur',
    component: EntrepreneurDetails
  },
  {
    path: '/entrepreneur/makerForum',
    name: 'entrepreneurMakerForum',
    component: EntrepreneurMakerForum
  },
  {
    path: '/entrepreneur/makerStyle',
    name: 'entrepreneurMakerStyle',
    component: EntrepreneurMakerStyle
  },
  {
    path: '/entrepreneur/makerForum/moreDetails',
    name: 'entrepreneurMakerForumMoreDetails',
    component: EntrepreneurMoreDetails,
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/entrepreneur/makerForum/videoDetails',
    name: 'entrepreneurMakerForumVideoDetails',
    component: EntrepreneurVideoDetails
  },

  /***** smartEngineering *****/
  {
    path: '/smartEngineering',
    name: 'smartEngineering',
    component: SmartEngineering
  },
  /***** GroupExchange *****/
  {
    path: '/groupExchange',
    name: 'groupExchange',
    component: GroupExchange,
    meta:{ isLogin:false,keepAlive:true }
  },

  /***** SechaFestival *****/
  {
    path: '/sechaFestival',
    name: 'sechaFestival',
    component: SechaFestival,
    meta:{ isLogin:false }
  },
  /***** ExchangeCoupons *****/
  {
    path: '/exchangeCoupons',
    name: 'exchangeCoupons',
    component: ExchangeCoupons,
    meta:{ isLogin:true,keepAlive:true }
  },
  /***** 我能兑换 *****/ 
  {
    path: '/exchangeCoupons/exchangeList',
    name: 'exchangeCouponsExchangeList',
    component: ExchangeList,
    meta:{
      keepAlive:true
    }
  },
  /***** 点券商品详情 *****/ 
  {
    path: '/exchangeCoupons/exchangeView',
    name: 'exchangeCouponsExchangeView',
    component: ExchangeView
  },
  /***** 会员专区 *****/ 
  {
    path: '/exchangeCoupons/memberZone',
    name: 'memberZone',
    component: memberZone
  },
  /***** 点券明细 *****/ 
  {
    path: '/exchangeCoupons/ticketingDetails',
    name: 'ticketingDetails',
    component: ticketingDetails
  },
  /***** 点券充值 *****/ 
  {
    path: '/exchangeCoupons/recharging',
    name: 'recharging',
    component: recharging
  },
  /***** Search *****/
  {
    path: '/search/search',
    name: 'searchSearch',
    component: Search
  },
  /***** DecorationSquare *****/
  {
    path: '/decorationSquare',
    name: 'decorationSquare',
    component: DecorationSquare,
    meta:{ keepAlive:true,scrollTop: 0 }
  },

]

const router = new VueRouter({
  mode: 'hash',//采用哪种路由模式,默认是history,可改为hash
  routes,
  // 路由跳转启动keepAlive就记录上次滚动的位置，否则就回到顶部
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition){
      return savedPosition
    }else{
      return{
        x:0,
        y:0
      }
    }
  }
})

//阻止路由重复跳转同个页面
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.options.routes.find((itme,index)=>{
  let personalArr=[];
  if(itme.name=="personal"){
    itme.children.find((childrenObj,childrenIndex)=>{
      personalArr.push({name:childrenObj.name,key:childrenIndex});
    })
    personalArr=[...new Set(personalArr)];
    store.commit("savePersonalRightArr",personalArr);
  }
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  next()
  console.log(to.meta);
  if(to.meta.isLogin&&!store.state.login){
    Vue.prototype.$message({
      message: "尚未登录！",
      type: 'warning'
    })
    next({
      path: "/login?loginUrl=login"
    });
  }
  // console.log("to",to);
  // console.log("from",from);
  // if (to.path === "/login") {
  //   // 如果是访问登录界面，如果用户会话信息存在，代表已登录过，跳转到主页
  if (!to.name) {
    next({
      path: "/"
    });
  } else {
    next();
  }
  // }
  //  else {
  //   if (userInfo == null) {
  //     // 如果访问非登录界面，且户会话信息不存在，代表未登录，则跳转到登录界面
  //     next({
  //       path: "/login"
  //     });
  //   } else {
  //     next();
  //   }
  // }
});

// 路由后置钩子函数
router.afterEach((to,from)=>{
  // 如果进入后的页面不想需要缓存，则设置scrollTop = 0
})

export default router