/*
 * @Author: 封闭货车 2813002873@qq.com
 * @Date: 2022-06-24 09:32:56
 * @LastEditors: maojiawei 18970691631@163.com
 * @LastEditTime: 2023-11-13 14:20:06
 * @FilePath: \hh\PC\h6\src\api\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import store from '../store.js'
import qs from 'qs'
import Vue from 'vue';
import router from '@/router/index.js';
// import { Loading } from 'element-ui';

function request(method, url, object = null) {
    let data;
    if (object instanceof FormData) {
        data = object;
    } else {
        data = { ...object };
    }
    const msdjs = require('./md5.js');
    const server = 'https://m.zhikecheng.cn';

    let urlArr = url.split('?')[1];
    let urlParams = new URLSearchParams('?' + urlArr);
    let time = new Date().getTime() / 1000;
    let key = localStorage.getItem('userInfo');
    let api_signMd5 = ksort(data);
    let forinstring = "";

    if (!(object instanceof FormData)) {
        data.act = urlParams.get('act');
        data.op = urlParams.get('op');
        data.comefrom = 'web'; //设备
        data.api_time = time.toFixed(0); // 时间戳
        data.key = key ? JSON.parse(key).key : ''; //获取用户的 key

        for (let key in api_signMd5) {
            if (key != "file") {
                forinstring += "&" + key + "=" + api_signMd5[key]
            }
        }
        api_signMd5 = forinstring.replace("&", "") + 'AWEB58696587452';
        data.api_sign = msdjs.hexMD5(api_signMd5).toUpperCase(); // 加密签名
        data.api_sign = data.api_sign.toLocaleLowerCase();
    }

    function ksort(o) {//参数排序
        let sorted = {},
            keys = Object.keys(o);
        keys.sort();
        keys.forEach((key) => {
            sorted[key] = o[key];
        })
        return sorted;
    }
    let format = method === 'get' ? { method, url, params: data } : url.indexOf('op=upload') > -1 || url.indexOf('op=shopAuthUpload') > -1 ? {
        method, url, data: data, headers: { 'content-type': 'multipart/form-data;charset=utf-8', 'Accept': '*/*' }
    } : { method, url, data: qs.stringify(data), headers: { 'content-type': 'application/x-www-form-urlencoded' } }
    return axios(format);
}

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // const nowNum = Math.random();
    if (config.url) {
        // config.url=config.url+'?rnd='+nowNum;
    }
    if (!config.validateStatus()) {
        setTimeout(() => {
            store.state.loading = false;
        }, 2000)
    }
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
let timer = null;
axios.interceptors.response.use(function (response) {
    // console.log(router.history.current.name);
    if (response.data.message == "token已失效，您已在其他客户端登录" || response.data.message == "请登录，Token为空") {
        store.commit("clear");

        //如果路径是下面这些就不进行跳转登录
        if (router.history.current.name!= 'searchSearch'
            &&router.history.current.name!='publicPageShoppingList'
            &&router.history.current.name!='groupExchange'
            &&router.history.current.name!='sechaFestival'
        ) {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                router.push('/login?loginUrl=login');
                Vue.prototype.$message({
                    type: 'warning',
                    message: '尚未登录！',
                })
            }, 100)
        }
    }
    store.state.loading = false;
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default request