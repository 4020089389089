// rem等比适配配置文件

// 基准大小，配合 px2rem 一起使用的时候，这个基准是多少已经不重要了，保持跟 px2rem 的基准一样就可以了
const baseSize = 16
// 设置 rem 函数
function setRem () {
  // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
  let scale = document.documentElement.clientWidth / 1920
  if(scale < 1){
    scale = 1;
  }
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  // setRem();
}