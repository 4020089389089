import Vue from 'vue'
import Vuex from 'vuex'
import oderData from './store/oderData'
// import home from './api/layoutApi/home'
import axios_api from './api/layoutApi/index'
import checkRule from './util/checkRule'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {//属性
        // httpUrl:'https://m.zhikecheng.cn/',
        // activeIndex:'0',//首页列表状态
        isNavBar: true,//navbar状态
        showGroup: true,//search显示和隐藏
        sort_key: '',
        order: '',
        user: JSON.parse(localStorage.getItem('userInfo'))?JSON.parse(localStorage.getItem('userInfo')):'',//用户key
        login: JSON.parse(localStorage.getItem('isLogin')) || false,
        content: '',
        api: 'https://m.zhikecheng.cn',
        // upload_url:"https://m.zhikecheng.cn/mobile/index.php?act=member_feedback&op=upload",
        oderData: oderData,
        loading: false,
        floor: '1A',
        activeName: '0',//我的订单状态
        personalRightArr:[],//个人中心右边数据数组
        applyStatusData:[
			{ name:'未付款',status:"0" },
			{ name:'已付款',status:"1" },
			{ name:'申请退款中',status:"2" },
			{ name:'大后台审核中',status:"3" },
			{ name:'退款驳回（失败）',status:"4" },
			{ name:'已退款',status:"5" },
			{ name:'订单完结',status:"6" },
		],//申请状态数据
        scrollTop: 0,//保存的滚动条位置
        is_clean_up:0,//消息中心清除标识
        memberInfo:null,//用户信息
    },
    mutations: {//不能异步方法
        // 保存修改的滚动条位置
        recordScroll(state, scrollTop) {
            state.scrollTop = scrollTop;
        },
        //改变title标题名
        changeContent(state, string) {
            state.content = string;
        },
        //消息中心清除标识
        save_is_clean_up(state, data) {
            state.is_clean_up = data;
        },
        // 保存个人中心右边数据数组
        savePersonalRightArr(state, arr){
            state.personalRightArr = arr;
        },
        // 改变loading状态
        changeLoading(state, data){
            state.loading = data;
        },
        //检测到用户未登录或者在异地登录跳转到登录页面要求用户登录
        isLogin(state, obj) {
            // console.log("obj",obj);
            if (obj.res.data.error_code === 403 || obj.res.data.error_code === 401) {
                // obj.that.$store.commit('addLogin', false);
                // obj.that.$message({
                //     message: "尚未登录！",
                //     type: 'warning'
                // });
                // obj.that.$router.push('/login?loginUrl=login');
            } else if (obj.res.data.error_code&&obj.res.data.error_code != 0) { //接口返回的状态码存在且不为0时，执行
                    obj.that.$message({
                        message: obj.res.data.message,
                        type: 'warning'
                    });
            } else if (obj.res.data.state&&obj.res.data.state != 0&&obj.res.data.state != 1) {
                obj.that.$message({
                    message: obj.res.data.msg,
                    type: 'warning'
                });
            }
        },
        //用户登录缓存key
        addUser(state, payload) {
            state.user = payload;
            localStorage.setItem('userInfo', JSON.stringify(state.user));
        },
        //用户是否登录
        addLogin(state, isLogin) {
            state.login = isLogin;
            localStorage.setItem('isLogin', JSON.stringify(state.login));
            // console.log(localStorage.getItem('userInfo'),889999);
        },
        // 保存用户信息
        saveMemberInfo(state, data){
            state.memberInfo = data;
            localStorage.setItem('memberInfo', JSON.stringify(state.memberInfo));
        },
        clear(state) {
            state.user = '';
            state.login = false;
            localStorage.clear();
        },
    },
    actions: {//能异步方法
         // 获取会员消息
         getMember_Message(context,data){
            axios_api.home.getMember_Message({
                key: context.state.user ? context.state.user.key : "",
            }).then((res)=>{
                if(res.data.error_code===0){
                    context.commit("save_is_clean_up",res.data.datas.total_num);
                }
            })
        },
        //获取余额信息
        getBalanceInfo(context,data){
            let that = data;
            axios_api.exchangeCoupons.getBalanceInfo({
                key: context.state.user ? context.state.user.key : '',
            }).then((res)=>{
                context.commit('isLogin', { res: res, that: that });
                if(res.data.error_code===0){
                    that.balanceInfo=res.data.datas.balance_info;
                }
            })
        },
        //获取设计之都首页视频列表
        getAestheticsCaseList(context,datas){
            let { that,data:case_Info  } = datas;  
            axios_api.cityOfDesign.aestheticsCaseList({
                type: case_Info.type,
                curpage: case_Info.curpage,
                pagesize: case_Info.pagesize,
                designer_id: case_Info.designer_id,
                is_groom: case_Info.is_groom,
                style_id: case_Info.style_id,
                bedroom_id: case_Info.bedroom_id,
                square_id: case_Info.square_id,
                sort: case_Info.sort,
                title: case_Info.title,
                ctype: case_Info.ctype
            }).then((res) => {
                context.commit('isLogin', { res: res, that: that });
                if(res.data.error_code===0){
                    that.caseList = res.data.datas.list;
                    that.decorationPaged = res.data.datas.paged;
                }
            })
        },

    },
    getters: {//计算属性

    }
})
