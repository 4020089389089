let publicMethod = {

    //公共检验方法
    test(state, value) {
        let regular;
        let tip;
        //邮箱格式
        const email = /^(([0-9a-zA-Z]+)|([0-9a-zA-Z]+[_.0-9a-zA-Z-]*[0-9a-zA-Z]+))@([a-zA-Z0-9-]+[.])+([a-zA-Z]{2}|net|NET|com|COM|gov|GOV|mil|MIL|org|ORG|edu|EDU|int|INT)$/;

        //手机号码
        const phone = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;

        //用户名(用户名长度6-20位，由数字，字母，下划线组成，且不能以数字开头，字母不区分大小写)
        const user = /^[A-Za-z_]{1}[A-Za-z\d_]{5,19}$/;

        //密码 (密码长度为8-20位，由数字，字母，下划线组成，且至少包含两种及以上字符，字母区分大小写)
        const password = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![_]+$)[0-9_A-Za-z]{7,19}$/;

        switch (state) {
            case 'email':
                regular = email;
                tip = '邮箱格式'
                break;
            case 'phone':
                regular = phone;
                tip = '电话号码'
                break;
            case 'user':
                regular = user;
                tip = '用户名格式'
                break;
            case 'password':
                regular = password;
                tip = '密码格式'
                break;
            default:
                return false
                break;
        }

        //检索正则
        let re = new RegExp(regular);

        if (re.test(value)) {
            return `输入正确`
        }
        else {
            return `请输入正确的${tip}`
        }
    }

}

export default publicMethod;