import store from "../store";

/*** 手机号码校验 ***/ 
function checkPhone(phone){
	let valid_rule = /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[8-9])[0-9]{8}$/;// 手机号码校验规则
	if(valid_rule.test(phone)){
		return true;
	}else{
		return false;
	}
}

/*** 获取年月 ***/ 
function getYearOrMonth(){
	let date = new Date();
	let year = date.getFullYear(); //获取完整的年份(4位,如:1970)
	let month = date.getMonth() + 1;//获取月份(返回0-11,0代表1月,用的时候记得加上1)
	let today_month= year+'-'+month;
	return today_month;
}

/*** 数组元素置顶 (数组的某项替换至第一的位置) ***/ 
function toFirst(arr, index){
	if (index != 0) {
		arr.unshift(arr.splice(index, 1)[0])
	}
	return arr;
}

/*** 参数排序 ***/ 
function ksort(o) {
	let sorted = {},
	  keys = Object.keys(o);
	  keys.sort();
	  keys.forEach((key) => {
	  sorted[key] = o[key];
	});
	return sorted;
}

/*** 上传formData对象处理参数 ***/ 
function upload_query(file){
	const msdjs = require("../api/md5");
      let formData = new FormData();
      let time = new Date().getTime() / 1000;
      formData.append("file", file.raw);
      formData.append("key",store.state.user.key);
      formData.append("api_member_id", store.state.user.member_id);
      formData.append("api_member_name", store.state.user.username);
      formData.append("comefrom", "web");
      formData.append("api_time", time.toFixed(0)); // 时间戳
      // // 生成api_sign
      let api_signMd5 = ksort(formData);
      let forinstring = "";
      for (let key in api_signMd5) {
        if (key != "file") {
          forinstring += "&" + key + "=" + api_signMd5[key];
        }
      }
      api_signMd5 = forinstring.replace("&", "") + "AWEB58696587452";
      formData.append("api_sign",msdjs.hexMD5(api_signMd5).toUpperCase().toLocaleLowerCase()); // 加密签名
	return formData
}

let checkRule={
	checkPhone,
	getYearOrMonth,
	toFirst,
	upload_query
}

export default checkRule;




