import store from '../../store'
import request from '../request'

const http = {
    mobile: 'pc',
}
const apis = {
    //获取验证码
    login(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=login&op=sendCode`, params)
    },
    //获取修改密码验证码
    getChangePassWordCode(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_security&op=send_auth_code`, params)
    },
    // 修改密码手机验证码提交接口
    confirmSubmit(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_security&op=auth_submit`, params)
    },
    // 登录
    signIn(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=login&op=loginThreeWay`, params)
    },
    // 注册
    register(params){
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=login&op=register`, params)
    },
    // 修改密码
    changePassWord(params){
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_security&op=modify_pwd`, params)
    },
    // 隐私协议
    privacyDoc(params){
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=document&op=privacy_doc`, params)
    }
}
export default apis;
