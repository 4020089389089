import store from '../../store'
import request from '../request'

const http = {
    mobile: 'pc',
    wisdom: 'wisdom'
}
const apis = {
    //我的拼团列表
    getMyGroupList(params){
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=spellgroup&op=my_spellgroup_list`, params)
    },
    // 优惠券列表
    getCouponList(params){
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_voucher&op=voucher_list`, params)
    },
    // 我的商城/个人中心数据
    getMyShopping(params){
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_index&op=index`, params)
    },
    // 预约信息
    getAppointmentList(params){
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_sub&op=list`, params)
    },
    //收藏视频/案例类别数据
    getCollectionCase(params){
        return request('post', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=aesthetics_case_like_list`, params)
    },
    //点券明细数据列表
    getCouponDetailList(params){
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_points&op=index`, params)
    },
    //余额明细列表
    getBalanceDetailsList(params){
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=predeposit&op=yck_list`, params)
    },
    //获取支付密码验证方式的信息
    getVerificationMethodInfo(params){
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_index&op=getInfo`, params)
    },
    //设置支付密码
    setPaymentPassword(params){
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_security&op=modify_paypwd`, params)
    },
    //获取账户安全信息接口
    getAccountSecurityInfo(params){
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_security&op=member`, params)
    },
    //手机号码绑定
    bindMobileNumber(params){
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_security&op=modify_mobile`, params)
    },
    //发送手机验证码
    sendMobileCode(params){
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_security&op=send_modify_mobile`, params)
    },
    //获取亲情号信息
    getFamilyInfo(params){
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=family&op=log`, params)
    },
    //亲情号提交
    submitKinshipNumberBind(data){
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=family&op=bind`, data)
    },
    //申请成为分销商接口
    applyDistributionStore(data){
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=distribution&op=apply_com`, data)
    },
    //获取审核进度
    getApplyRateofProgress(data){
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=distribution&op=getApplyRs`, data)
    }
}
export default apis;
