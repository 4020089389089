/*
 * @Author: 封闭货车 2813002873@qq.com
 * @Date: 2022-06-24 09:32:56
 * @LastEditors: 封闭货车 2813002873@qq.com
 * @LastEditTime: 2022-07-04 11:12:00
 * @FilePath: \hh\PC\h6\src\api\page.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE获取
 */
import store from '../../store'
import request from '../request'
const http = {
    mobile: 'pc',
    mall: 'mall',
    wisdom: 'wisdom',
    butler: 'butler'
}
const apis = {
    //获取首页智能促销列表数据
    promoShoppingList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=goods&op=class_goods_list`, params)
    },
    //获取热销榜列表数据
    getShoppingList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=goods&op=goods_list`, params)
    },
    //智慧商场各个馆看案例/看视频
    case_ad_list(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=index&op=case_ad_list`, params)
    },
    //智慧商场各个馆详情
    vrmallInfo(params) {
        return request('get', `${store.state.api}/${http.wisdom}/index.php?act=api-wishop&op=vrmall_info`, params)
    },
    //系列详情
    vrseriesList(params) {
        return request('get', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=vrseries_list`, params)
    },
    //视频列表详情
    videoList(params) {
        return request('get', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=aesthetics_case_list`, params)
    },
    //案例分类列表详情
    vrCategory(params) {
        return request('get', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=vr_category`, params)
    },
    //案例详情
    designSchemeList(params) {
        return request('get', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=design_scheme_list`, params)
    },
    //获取列表栏数据
    scheme(params) {
        return request('post', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=aesthetics_case_info`, params)
    },
    //购物车商品 商品详情展示
    shoppingDetails(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=goods&op=goods_body`, params)
    },
    //设计师详情
    designerInfo(params) {
        return request('post', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=designer_info`, params)
    },
    //签到特惠
    memberSignIndex(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_sign&op=index`, params)
    },
    //加入购物车
    addShopping(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_cart&op=cart_add`, params)
    },
    //商品详情（宝贝评价）
    shoppingEvaluate(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=goods&op=goods_evaluate`, params)
    },
    //切换收货地址
    memberBuyChangeAddress(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_buy&op=change_address`, params)
    },
    //确认订单 商品详情
    shoppingBuy_step1(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_buy&op=buy_step1`, params)
    },
    //确认订单 获取订单号
    shoppingBuy_step2(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_buy&op=buy_step2`, params)
    },
    //确认订单 跳转支付
    shoppingBuy_pay(params) {
        return request('post', `${store.state.api}/${http.mall}/index.php?act=payment&op=pay`, params)
    },
    //确认订单 点券跳转支付
    ticketsBuy_pay(params) {
        return request('post', `${store.state.api}/${http.mall}/index.php?act=payment&op=pgoods_order`, params)
    },
    // 会员订单支付
    memberOrder_Buy_pay(params) {
        return request('post', `${store.state.api}/${http.mall}/index.php?act=payment&op=vip_order`, params)
    },
    // 获取会员订单
    getMemberOrder(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=vip&op=order`, params)
    },
    // 获取支付信息
    getPaymentInfo(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_payment&op=pay`, params)
    },
    // 获取预付款信息
    getAdvanceArticleInfo(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=article&op=article_show`, params)
    },
    // 获取退款说明
    getRefundExplain(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_refund&op=add_refund_all`, params)
    },
    // 获取退款说明
    imageUpload(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_refund&op=upload_refund_pic`, params)
    },
    // 获取支付密码
    getPaymentPassword(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_security&op=member`, params)
    },
    //免费领 补签
    memberSignSupsign(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_sign&op=supsign`, params)
    },
    //免费领 签到
    memberSignSign(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_sign&op=sign`, params)
    },
    //免费领 领取奖励
    memberSignReward(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_sign&op=reward`, params)
    },
    //免费领 兑换点券
    voucherexchangeSave(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=pointvoucher&op=voucherexchange_save`, params)
    },
    //智能管家详情页
    apiArticleView(params) {
        return request('get', `${store.state.api}/${http.butler}/index.php?act=api-article&op=view`, params)
    },
    // 成为会员
    getBecomeMember(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=vip&op=index`, params)
    },
    // 图片上传接口
    uploader(data) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_feedback&op=upload`, data)
    },
    // 提交反馈接口
    submit_feedback_add(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_feedback&op=feedback_add`, params)
    },
   
}
export default apis;