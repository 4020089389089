/*
 * @Author: 封闭货车 2813002873@qq.com
 * @Date: 2022-06-24 09:32:56
 * @LastEditors: 封闭货车 2813002873@qq.com
 * @LastEditTime: 2022-07-04 11:12:00
 * @FilePath: \hh\PC\h6\src\api\page.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE获取
 */
import request from '../request'
import store from '../../store'
const http = {
    mobile: 'pc',
    wisdom: 'wisdom',
    butler: 'butler',
    business:"business"
}
const apis = {
    //创业者首页广告
    entrepreneurAdv(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=h5_adv&op=Adv337`, params)
    },

    //创业者首页列表
    entrepreneurList(params) {
        return request('get', `${store.state.api}/${http.business}/index.php?act=api-index&op=index`, params)
    }, 

    //创业讲坛

    //广告图
    makerForumAdv(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=h5_adv&op=Adv336`, params)
    },

    //推荐视频列表
    makerForumVideoList(params) {
        return request('get', `${store.state.api}/${http.business}/index.php?act=api-video&op=index`, params)
    },


    //推荐视频详情页列表
    moreDetailsVideoList(params) {
        return request('get', `${store.state.api}/${http.business}/index.php?act=api-video&op=list`, params)
    },

    //推荐视频详情页
    moreDetailsVideoView(params) {
        return request('get', `${store.state.api}/${http.business}/index.php?act=api-video&op=view`, params)
    },
}
export default apis;