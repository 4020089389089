import store from '../../store'
import request from '../request'

const http = {
    mobile: 'pc',
    wisdom: 'wisdom'
}
const apis = {
    // 收藏列表
    getCollectionList(params){
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_favorites&op=favorites_list`, params)
    },
    // 添加收藏
    addCollection(params){
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_favorites&op=favorites_add`, params)
    },
    // 删除收藏接口
    DeleteCollection(params){
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_favorites&op=favorites_del`, params)
    },
}
export default apis;