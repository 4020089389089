<template>
    <div class="nav_bar">
        <div class="nav_bar_top" ref="nav_bar_icon" @click="routeJump($event)">
            <div class="nav_bar_logo">
                <img src="../../images/智科城.svg" alt="">
            </div>
            <div class="nav_menu_left">
                <span v-for="(item,index) of navMenuLeftArr" :key="index" :id="item.path">{{item.name}}</span>
                <!-- <span id="/home"> 首页 </span>
                <span id="/category"> 分类</span>
                <span id="/shopping">购物车</span>
                <span id="/myOrder">我的订单</span>
                <span id="/personal">个人中心</span>
                <span id="/my">关于我们</span> -->
            </div>
            <div class="nav_menu_right">
                <div class="nav_menu_right_member" @click="goTo_become_member">
                    <div>
                        <div>{{ $store.state.user.is_vip=='1'?'已成为会员':'成为会员' }}</div>
                        <el-image style="width: 16px; height: 16px;margin-left:5px;" src="https://m.zhikecheng.cn/images/images/my/vip.png"></el-image>
                    </div>
                </div>
                <div id="/login" :class="[$store.state.login?'is_login':'','default_login']" >
                    <el-avatar v-if="$store.state.login" size="medium" :src="$store.state.user.member_avatar"></el-avatar>
                    {{ $store.state.login ? $store.state.user.username : '你好，请登录'}}
                    <div class="dropdown_menu">
                    <div class="dropdown_menu_triangle"></div>
                        <div class="dropdown_menu_main">
                            <div class="dropdown_menu_main_centent" @click="handle_dropdownMenu(item)" v-for="(item,index) of dropdown_menu" :key="index">
                                <i :class="item.icon"></i>
                                {{ item.name }}
                                <el-badge v-if="item.value==1" :value="$store.state.is_clean_up" />
                            </div>
                        </div>
                    </div>
                </div>
                <span id="/register" v-if="!$store.state.login">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;免费注册</span>
            </div>
        </div>
    </div>
</template>

<script>
// import { ref, trigger } from '@vue/reactivity';

export default {
    data() {
        return {
            isWidth: true,
            navMenuLeftArr:[
                {name:'首页',path:'/home'},
                {name:'分类',path:'/category'},
                {name:'购物车',path:'/shopping'},
                {name:'我的订单',path:'/myOrder'},
                {name:'个人中心',path:'/personal'},
                {name:'关于我们',path:'/my'},
            ],
            dropdown_menu:[
                { name:'意见反馈',path:'/publicPage/feedback',value:0,icon:'el-icon-edit-outline' },
                { name:'消息',path:'/messageCenter/systemMessages?message_type=1',value:1,icon:'el-icon-chat-line-round' },
                { name:'退出',path:'',value:2,icon:'el-icon-switch-button' },
            ],//隐藏菜单
        };
    },
    created() { 
        console.log('this.user',this.$store.state.user);
      },
    mounted() {  },
    methods: {
        goTo_become_member(){
            this.$router.push('/publicPage/becomeMember');
        },
        // 点击隐藏菜单
        handle_dropdownMenu(item){
            switch(item.value){
                // case 1||0:
                //     this.$router.push(item.path);
                //     break; 
                case 2:
                    this.open();
                    break;
                default:
                    this.$router.push(item.path);
                    break;
            }
        },
        open() {
            this.$confirm('确认退出账号, 是否继续?', '退出', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '退出成功!'
                });
                this.$router.push('/login?loginUrl=login');
                this.$store.commit('clear');
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消退出'
                });
            });
        },
        routeJump(e) {
            let event = e || window.event;
            //当点击注销按钮时
            if (event.target.id == 'cancellation') {
                this.open();
                return
            }
            let isLogin = event.target.id == '/register' || event.target.id == '/login';
            //当点击登录按钮时
            if (!this.$store.state.login && (event.target.id == '/register' || event.target.id == '/login')) {
                let loginUrl = event.target.id.replace(/\//, '')
                this.$router.push({ path: 'login', query: { loginUrl } });
                return
            }
            if (event.target.id && !isLogin) {
                this.$router.push(event.target.id);
            }
        }
    }
}
</script>

<style lang="less">
// 隐藏菜单
.dropdown_menu{
    position: absolute;
    top: 22px;
    left: 25%;
    transform: translateX(-50%);
    cursor: auto;
    display: none;
    z-index: 10;
    .dropdown_menu_triangle{
        width: 0px;
        height: 0px;
        border: 10px solid transparent;
        border-bottom-color:#ffffff;
        margin: 0 auto;
    }
    .dropdown_menu_main{
        background-color: #ffffff;
        // height: 200px;
        width: 90px;
        padding: 18px;
        box-shadow: 0 0 10px 2px rgb(0 0 0 / 6%);
        border-color: rgb(0 0 0 / 6%);
        // text-align: center;
        .dropdown_menu_main_centent{/*  */
            color: black;
            height: 40px;
            line-height: 40px;
            cursor: pointer;
            font-size: 16px;
            position: relative;
            .el-badge{
                position: absolute !important;
                // right: 0;
                // top: 0;
            }
        }
        .dropdown_menu_main_centent:hover{
            background-color: #F0F0F5;
        }
    }
}
.is_login:hover{
    .dropdown_menu{
        display: block;
    }
}
.default_login{
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    .el-avatar{
        margin-right: 6px;
    }
}
.nav_bar {
    background-color: #313132;
    color: #ffffff;
    padding: 0 12px;
    font-size: 14px;
    box-sizing: border-box;
    color: #F5F5F7;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    .nav_bar_top {
        width: @width1200;
        display: flex;
        height: 48px;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        .nav_bar_logo {
            width: 24px;
            height: 24px;
            position: absolute;
            img {
                width: 100%;
                height: 100%;
            }
        }

        .nav_menu_left {
            span {
                cursor: pointer;
                margin-left: 56px;
            }
        }

        .nav_menu_right {
            display: flex;
            align-items: center;
            div {
                // cursor: pointer;
            }
            .nav_menu_right_member{
                cursor: pointer;
                margin-right: 50px;
                >div{
                    display: flex;
                    align-items: center;
                }
            }
            .is_login {
                // cursor: no-drop;
            }
        }

    }

}

.el-icon-user-solid {
    font-size: 18px;
}
</style>
