/*
 * @Author: 封闭货车 2813002873@qq.com
 * @Date: 2022-06-24 09:32:56
 * @LastEditors: 封闭货车 2813002873@qq.com
 * @LastEditTime: 2022-07-04 11:12:00
 * @FilePath: \hh\PC\h6\src\api\page.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE获取
 */
import store from '../../store'
import request from '../request'
const http = {
    mobile: 'pc',
    wisdom: 'wisdom',
}
const apis = {
    
    //点券换购广告等数据
    exchangeCouponsIndex(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=pointprod&op=index`, params)
    },

    //点券换购商品列表数据
    exchangeCouponsPlist(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=pointprod&op=plist`, params)
    },

    //点券换购商品详情数据
    exchangeCouponsPinfo(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=pointprod&op=pinfo`, params)
    },
    //规则与要点
    pointprodRule(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=pointprod&op=rule`, params)
    },
    //点券兑换第一步
    pointcartStep1(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=pointcart&op=step1`, params)
    },
    // 获取余额信息
    getBalanceInfo(params){
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_index&op=getMemberBalance`,params)
    },
    //点券兑换第二步
    pointcartStep2(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=pointcart&op=step2`, params)
    },
    //获取会员专区列表
    getMemberZoneList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=pointprod&op=plist`, params)
    },
    //获取点券列表
    getTicketingDetailsList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_points&op=index`, params)
    },
    //获取点券列表
    getTicketingDetailsList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_points&op=index`, params)
    },
    //获取充值金额列表
    getRechargeAmountList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_points&op=recharge`, params)
    },
    //获取充值金额支付列表
    getPaymentList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_payment&op=getPaymentList`, params)
    },
    // 获取充值生成订单
    getRechargeOrder(params){
        return request('get', `${store.state.api}/${'mobile'}/index.php?act=member_points&op=recharge_add`, params)
    }
}
export default apis;