import store from '../../store'
import request from '../request'
const http = {
    mobile: 'mobile',
    wisdom: 'wisdom',
}
const apis = {
    // 360装新家首页数据
    renovationNewHomeData(params){
        return request('get',`${store.state.api}/${http.mobile}/index.php?act=homeproject&op=index`,params)
    },
     // 查看清单
     homeproject(params){
        return request('get',`${store.state.api}/${http.mobile}/index.php?act=homeproject&op=vify`,params)
    },
    // 获取套装数据
    homeprojectGoodslist(params){
        return request('get',`${store.state.api}/${http.mobile}/index.php?act=homeproject&op=goods_list`,params)
    },
    // 支付接口
    createOrder(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=homeproject&op=create_order`, params)
    },
    // 订单列表
    homeprojectOrderlist(params){
        return request('get',`${store.state.api}/${http.mobile}/index.php?act=homeproject&op=order_list`,params)
    },
     //订单详情数据
     homeprojectOrderdetail(params){
        return request('get',`${store.state.api}/${http.mobile}/index.php?act=homeproject&op=get_order_detail`,params)
    },
    // 申请退款
    homeprojectRefundOrder(params){
        return request('post',`${store.state.api}/${http.mobile}/index.php?act=homeproject&op=refund_order`,params)
    },
    
}

export default apis;