/*
 * @Author: 封闭货车 2813002873@qq.com
 * @Date: 2022-06-24 09:32:56
 * @LastEditors: 封闭货车 2813002873@qq.com
 * @LastEditTime: 2022-07-04 11:12:00
 * @FilePath: \hh\PC\h6\src\api\page.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE获取
 */
import request from '../request'
import store from '../../store'

const http = {
    mobile: 'pc',
    wisdom: 'wisdom',
    butler: 'butler'
}
const apis = {
    //设计之都视频列表
    aestheticsCaseList(params) {
        return request('get', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=aesthetics_case_list`, params)
    },
    //设计之都广告图
    aestheticsAdv(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=h5_adv&op=Adv330`, params)
    },
    //找施工
    aestheticsAdv2(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=h5_adv&op=Adv331`, params)
    },
    decorationCategoryList(params) {
        return request('get', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=decoration_category_list`, params)
    },
    decorationCompanyList(params) {
        return request('post', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=decoration_company_list`, params)
    },
    constructionDetails(params) {
        return request('post', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=decoration_company_info`, params)
    },
    buildAnli(params) {
        return request('get', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=aesthetics_case_list`, params)
    },
     // 找施工预约接口
     submit_build_contact(params) {
        return request('post', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=build_company_advisory_add`, params)
    },
    //设计师排行榜
    designerList(params) {
        return request('get', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=designer_list`, params)
    },
    //预约设计师
    aestheticsCaseBookAdd(params) {
        return request('post', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=aesthetics_case_book_add`, params)
    },
    //选设计师下拉选项数据
    getDropdownOptionsInfo(params) {
        return request('post', `${store.state.api}/${http.wisdom}/index.php?act=api-wisdom&op=designer_category_list`, params)
    },
}
export default apis;