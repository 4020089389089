/*
 * @Author: 封闭货车 2813002873@qq.com
 * @Date: 2022-06-24 09:32:56
 * @LastEditors: 封闭货车 2813002873@qq.com
 * @LastEditTime: 2022-07-04 11:12:00
 * @FilePath: \hh\PC\h6\src\api\page.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE获取
 */
import store from '../../store'
import request from '../request'
const http = {
    mobile: 'pc',
}
const apis = {
    //地址详情
    addressList(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_address&op=address_list`, params)
    },
    //新增收货地址
    addressAdd(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_address&op=address_add`, params)
    },
    //删除收货地址
    addressDel(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_address&op=address_del`, params)
    },
    //编辑收货地址
    addressEdit(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_address&op=address_edit`, params)
    },
    //设置默认收货地址
    addressDefault(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_address&op=default_address`, params)
    },
    //物流信息
    getlogisticsDetails(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=seller_order&op=search_deliver`, params)
    },
    // 订单详情
    getOrderDetails(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_order&op=show_order`, params)
    },
    // 退款申请列表
    getRefundList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_refund&op=index`, params)
    },
    // 退货申请列表
    getReturnGoodsList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_return&op=index`, params)
    },
    // 换货申请列表
    getExchangeGoodsList(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_exchange&op=index`, params)
    },
    // 退款详情
    getRefundDetails(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_refund&op=view`, params)
    },
    // 点券订单列表
    getCouponOrderLsit(params) {
        return request('get', `${store.state.api}/${http.mobile}/index.php?act=member_pointorder&op=orderlist`, params)
    },
    // 取消订单接口
    cancelCouponOrder(params) {
        return request('post', `${store.state.api}/${http.mobile}/index.php?act=member_pointorder&op=cancel_order`, params)
    },
}
export default apis;