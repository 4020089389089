/*
 * @Author: 封闭货车 2813002873@qq.com
 * @Date: 2022-06-24 09:32:56
 * @LastEditors: 封闭货车 2813002873@qq.com
 * @LastEditTime: 2022-07-01 15:12:02
 * @FilePath: \hh\PC\h6\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import './util/rem'

import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import axios from 'axios'
import store from './store.js'
import ElementUI from 'element-ui'
import apis from './api/layoutApi/index'
import publicMethod from '@/api/publicMethod'
import 'element-ui/lib/theme-chalk/index.css'
import checkRule from './util/checkRule'
import format from './store/format'
// import Mint from 'mint-ui';
// import 'mint-ui/lib/style.css';
// Vue.use(Mint);
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)
Vue.use(ElementUI)
Vue.prototype.$axios = axios;
Vue.prototype.$api = apis;
Vue.prototype.$publicMethod = publicMethod;
Vue.prototype.$checkRule=checkRule;
Vue.prototype.$format=format;
// Vue.prototype.$confirm = confirm;
Vue.config.productionTip = false;


new Vue({
  axios,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
