import home from './home'
import login from './login'
import publicPage from './publicPage'
import communityCenter from './communityCenter'
import cityOfDesign from './cityOfDesign'
import entrepreneur from './entrepreneur'
import smartEngineering from './smartEngineering'
import sechaFestival from './sechaFestival'
import groupExchange from './groupExchange'
import personalApi from './personalApi'
import collection from './collection'
import exchangeCoupons from './exchangeCoupons'
import decorationSquare from './decorationSquare'
import search from './search'
import confirmOrder from './confirmOrder'
import renovationNewHome from './renovationNewHome'
let apis = {
    home,
    login,
    publicPage,
    communityCenter,
    cityOfDesign,
    entrepreneur,
    smartEngineering,
    sechaFestival,
    groupExchange,
    exchangeCoupons,
    decorationSquare,
    search,
    confirmOrder,
    personalApi,
    collection,
    renovationNewHome
};
export default apis;